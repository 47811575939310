exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-documentation-js": () => import("./../../../src/pages/documentation.js" /* webpackChunkName: "component---src-pages-documentation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-wordpress-plugin-js": () => import("./../../../src/pages/wordpress-plugin.js" /* webpackChunkName: "component---src-pages-wordpress-plugin-js" */),
  "component---src-templates-post-jsx-content-file-path-src-content-posts-generate-invoice-pdf-file-using-html-template-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/builds/shar-pdf/site/src/content/posts/generate-invoice-pdf-file-using-html-template.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-content-posts-generate-invoice-pdf-file-using-html-template-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-content-posts-how-to-convert-html-to-pdf-with-puppeteer-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/builds/shar-pdf/site/src/content/posts/how-to-convert-html-to-pdf-with-puppeteer.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-content-posts-how-to-convert-html-to-pdf-with-puppeteer-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-content-posts-transform-your-wordpress-page-into-the-pdf-file-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/builds/shar-pdf/site/src/content/posts/transform-your-wordpress-page-into-the-pdf-file.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-content-posts-transform-your-wordpress-page-into-the-pdf-file-mdx" */)
}

